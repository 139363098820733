/* Apply a fullscreen layout to the body */
body, html {
    margin: 0;
    height: 100%;
    font-family: Arial, sans-serif;
    background-color: #EAE6E3;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  
  .login-box {
    background-color: #F2EFEA;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 300px;
  }
  
  .login-image img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #dcdcdc;
  }
  
  h1 {
    margin: 20px 0;
    font-size: 24px;
    color: #333;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  input {
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  button {
    padding: 10px;
    margin: 10px 0;
    border: none;
    border-radius: 5px;
    background-color: #555;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #333;
  }
  
  .register-link {
    margin: 10px 0;
    color: #333;
    cursor: pointer;
  }
  
  .register-link span {
    text-decoration: underline;
  }
  
  .other-methods {
    margin-top: 20px;
  }
  
  .divider {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .divider hr {
    width: 40%;
    border: none;
    border-top: 1px solid #ccc;
  }
  
  .divider span {
    margin: 0 10px;
    color: #333;
  }
  
  .icons {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .icon {
    margin: 0 10px;
    font-size: 24px;
    cursor: pointer;
  }
  
  .icon:hover {
    color: #555;
  }
  
  .login-icon {
    width: 35px; /* Adjust width as needed */
    height: auto; /* Maintain aspect ratio */
  }