.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin: auto;
    justify-content: center;
}

.header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: absolute;
    top: 10px;
    padding: 0 20px;
}

.content{
    padding: 100px 20px 20px 20px; /* Adjust based on the height of the header and the help button wrapper */
    position: relative;
    overflow: auto;
    background: white;
}
