.card {
    min-width: 300px;
    max-width: 100%;  
    min-height: 100px;
    max-height: 250px;  
    width: auto;      
    height: auto;    
    perspective: 1000px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);   
    cursor: pointer;
    position: relative;
}
div.card{
    background-color: rgb(176, 193, 176);
}

.card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}
 
.card.flipped .card-inner {
    transform: rotateY(180deg);
}

.card-front, .card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
 
.card-back {
    transform: rotateY(180deg);
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-front-step-num {
    font-size: 16px;
    margin-bottom: 10px;
}
 
.card-front-content {
    font-size: 14px;
    padding:10px;
}

.card-back-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
} 

.flip-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 18px; 
    height: 18px; 
    background-image: url('../../images/flip-icon.png');
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    z-index: 10;
}