/* src/NavBar.css */
.navbar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: absolute;
    left:0;
    right:0;
    top: 0;
    background-color: #3d4c1b;  
}

.nav-drop-down {
    background-color: inherit; 
}

.navbar .nav-dropdown-title{
    background-color: #3d4c1b;
    border:none;
}

.navbar .nav-dropdown-item {
    color: white;
}

.navbar .nav-dropdown-item{
    background-color: #3d4c1b; /* Optional: Change this to your desired hover color */
    color: white;
}

.navbar .dropdown-toggle {
    color: white; /* Set the dropdown toggle text color */
}

  
.dropdown-toggle::after {
    content: none !important;
}
  
.nav-dropdown-title {
    display: flex;
    align-items: center;
    background-color: #3d4c1b; 
    border: none;
    padding-left: 25px; /* Adjust based on the size of the icon */
    position: relative;
    font-size: 19px;
  }
  
  .nav-dropdown-title::before {
    content: '';
    display: inline-block;
    width: 16px; /* Adjust size as needed */
    height: 16px; /* Adjust size as needed */
    background-image: url('../../images/navbar_dropdown.png'); /* Path to your icon */
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    background-color: white; 
    left: 5px; /* Adjust to position the icon */
  }
  
  