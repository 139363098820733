.card{
    display:flex;
    flex-direction: column;
    align-items: center;
    height: 15vh;
    width: 40vh;
    border-color: black;
    max-height: 25vh;
    margin: 3vh;
    
}

.shirtIcon, .growth-chart {
    font-size: 50px;
    padding-top: 10px;
    padding-right: 100px
}




.water-img{
    width: 6vh;
    height: 6vh;
}

.container{
    justify-content:center;

}

.card-content{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
}

.col{
    padding: 16px;
}

.row{
    margin-bottom: 10px;
}

.card-two-content{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1px;
}

.card-three-content{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
}

h2{
    font-size: 24px;
}

.custom-modal .modal-dialog {
    max-width: 400px; /* Adjust width as needed */
    margin: 1.75rem auto;
}

.custom-modal .modal-body {
    max-height: 300px; /* Adjust height as needed */
    overflow-y: auto;
}

.screenshot-preview img {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
}

.share-button {
    padding: 10px;
    margin-top: 20px; /* Adjust the top margin to space it from other content */
    border: none;
    border-radius: 5px;
    background-color: #555;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.share-button:hover {
    background-color: #333;
}
