@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;700&display=swap');

.App {
  text-align: center;
  background-color: white;
}

.container {
  background-color: white;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.back-arrow {
  cursor: pointer;
}

.profile-icon {
  width: 40px;
  height: 40px;
}

h2 {
  margin-top: 20px;
}

.scan-description {
  color: rgb(10, 10, 10);
  font-size: 18px;
  margin-top: 10px;
  text-align: center;
}

.scanner-container {
  background-color: #aeb4a6; /* Set background color */
  border: solid 0.5px gray;
  border-radius: 5px;
  padding: 20px; /* Add padding to make the content look better */
    margin: 5vh;
}

.scanner {
  margin: 20px;
  padding: 20px;
  border: solid 1px #ddd;
  border-radius: 5px;
  background-color: #f0f0f0;
}

.uploaded-image {
  max-width: 100%;
  max-height: 300px;
}

.placeholder {
  font-size: 50px;
  color: #ccc;
}

.button-container {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
}

.scan-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.scan-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
