.container {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  width: 100%;
}

.menu-icon, .profile-icon {
  font-size: 24px;
  cursor: pointer;
}

.add-friends, .search-friends {
  margin: 20px 0;
}

.search-bar {
  display: flex;
  align-items: center;
}

.search-bar input {
  flex: 1;
  padding: 8px;
  margin-right: 10px;
}

.search-bar button {
  padding: 8px 12px;
  cursor: pointer;
}

.friends-list {
  margin-top: 20px;
}

.friend-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.friend-icon {
  font-size: 24px;
}

.friend-name {
  flex: 1;
  margin-left: 10px;
}

.friend-options {
  font-size: 24px;
  cursor: pointer;
}
