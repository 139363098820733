.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-width: 400px;
    margin: auto;
    height: 100vh;
    justify-content: center;
    position: relative;
}

.header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: absolute;
    top: 10px;
    padding: 0 20px;
}
  
.back-arrow,
.profile-icon {
    font-size: 24px;
}

.card{
    height:600px;
    width:300px;
    padding: 20px;
    border: 1px solid #000; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #878c886e; 
    display: flex;
    flex-direction: column;
    align-items: center;

}

.title-section h1,
.title-section h2 {
  margin: 0;
}

.tote-img{

    width: 80%;
    height: 200px;
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10%;

}

.findtools {
    display: inline-block;
    width: auto;
}
  