.App {
  text-align: center;
  font-family: Arial, sans-serif;
}

.profile-icon {
  width: 30px;
  height: 30px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 400px;
  margin: 20px;
  height: 100vh;
  justify-content: space-between;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  top: 10px;
  padding: 0 20px;
}
.back-arrow{
  font-size: 24px;
  cursor: pointer;  
}

.profile-icon {
  font-size: 24px;
  cursor: pointer;
  width: 40px;
  height: 40px;
}

h2 {
  margin-top: 20px;
}

.scanner {
  width: 250px;
  height: 250px;
  border: 2px solid #ccc;
  border-radius: 10px;
  box-shadow: gray 0px 2px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.placeholder {
  font-size: 50px;
  color: #ccc;
}

.uploaded-image {
  max-width: 100%;
  max-height: 100%;
}

.button-container {
  width: 100%;
  height: 9.5vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 20% 0;
}

.scan-button {
  font-size: 16px;
  border-radius: 10px;
  cursor: pointer;
  width: 85%;
  height: 4vh;
}
