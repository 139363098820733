.container{
    text-align:center;
    padding:20px;
}

.title{
    font-size: 24px;
}

.styles-grid{
    display: grid;
    flex-wrap: wrap;
    justify-content: center;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

.style-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position:relative;
}

.style-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.image-placeholder {
    width: 100px;
    height: 100px;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    position: relative;
    object-fit: cover;
}

.style-item input[type="checkbox"] {
    margin-bottom: 10px;
    position: absolute;
    bottom: 3px; 
    left: 10px;
    z-index: 1;
}

.continue-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #ccc;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}